import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PdfViewer = ({ course, onClose }) => (
  <div className="pdf-viewer">
    <button onClick={onClose}>Close</button>
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
      <Viewer fileUrl={`/${course.pdf}`} />
    </Worker>
  </div>
);

export default PdfViewer;
