import React, { useState } from 'react';
import PdfViewer from '../components/PdfViewer';
import CourseItem from '../components/CourseItem';
import kid from '../assests/kid.jpg';
import flip2 from '../assests/flip2.jpg';
import flip3 from '../assests/flip3.jpg';
import './Courses.css';

const courses = [
  { id: 1, title: 'The Complete Indian Vocal Music ', image: flip3 },
  { id: 2, title: ' Kids Vocal Music', image: kid },
  { id: 3, title: 'Devotional Vocal Music', image: flip2 }
];

const Courses = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <div className='cource' >
      {selectedCourse ? (
        <PdfViewer course={selectedCourse} onClose={() => setSelectedCourse(null)} />
      ) : (
        <div className="course-list">
          {courses.map(course => (
            <CourseItem 
              key={course.id} 
              course={course} 
              onClick={() => setSelectedCourse(course)} 
              
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Courses;
