import React from 'react';
import './About.css';
import AboutImage from '../assests/profile.jpg'; // Replace with your image

function About() {
  return (
    <div className="about">
      <div className="banner">
        <div className="image-banner">
          <img src={AboutImage} alt="About Us" />
        </div>
        <div className="description-banner">
          <h2 className="title">About Us</h2>
          <p>
          Welcome to The Voicenotes Music Academy, founded by Priyani Vani Panditt,
           a distinguished figure in the Indian music industry with over 20 years of 
           experience as a playback singer, performer, and vocal coach. At our academy, 
           we are dedicated to providing top-notch music education and training to aspiring 
           singers. Through our comprehensive curriculum, expert instructors, and innovative teaching methods,
            we strive to cultivate a vibrant community of singers who are equipped with the knowledge, skills, and confidence to excel in their musical endeavors. Whether you're a beginner taking your first steps in music or an advanced musician honing your craft, we are committed to helping you reach your full potential .

          </p>
          <p>
          We extend a special thanks to Mrs. Neha Rathore, a renowned Piano teacher and performer based in Cupertino (San Francisco),
           who has been instrumental in shaping Priyani's journey as a teacher and mentor. Neha's guidance and support have been invaluable in the establishment of this academy,
            and we are grateful for her continued inspiration and collaboration. Together, we strive to create a nurturing environment where students can develop their musical talents and reach their fullest potential. 
            Join us at The Voicenotes Music Academy and embark on a transformative musical journey today.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
