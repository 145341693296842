import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReviewCard from './ReviewCard';
import { fetchReviews } from '../redux/reviewsSlice';
import './ReviewCarousel.css';

const ReviewCarousel = () => {
  const dispatch = useDispatch();
  const carouselRef = useRef(null);
  const { reviews, status } = useSelector((state) => state.reviews);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchReviews());
    }
  }, [dispatch, status]);

  let content;

  if (status === 'loading') {
    content = <div>Loading...</div>;
  } else if (status === 'failed') {
    content = <div>Error fetching reviews.</div>;
  } else if (reviews.length === 0) {
    content = <div>No reviews available.</div>;
  } else {
    content = reviews.map((review) => (
      <ReviewCard key={review.id} quote={review.content} author={review.name} />
    ));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.scrollBy({
          left: carouselRef.current.offsetWidth,
          behavior: 'smooth',
        });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="carousel-container">
      <button className="carousel-button left" onClick={scrollLeft}>
        &#9664;
      </button>
      <div className="carousel" ref={carouselRef}>
        {content}
      </div>
      <button className="carousel-button right" onClick={scrollRight}>
        &#9654;
      </button>
    </div>
  );
};

export default ReviewCarousel;
